.logo  {
    display: flex;
    gap: 10px;
    align-items: center;

    &__link {
       font-family: "Inter";
       font-size: 20px;
       font-weight: 500;
    }
}