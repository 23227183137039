.btn__social-media {
    cursor: pointer;
    display: flex;
    gap: 16px;
    width: 100%;
    height: 64px;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    background-color: white;
    justify-content: center;
    align-items: center;

    &:hover {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }

    img {
        display: inline-block;
    }
} 

.btn-group__social-media {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    width: 100%;
}