.modal-mail {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 480px;
    top: 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        padding: 0;
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }

    .text_grey-blue {
        width: 80%;
        border-radius: 8px;
        font-size: 14px;
        margin-top: 16px;
    }

    .btn-close {
        top: 10px;
        right: 10px;
        display: inline-block;
        position: absolute;
        background-image: url('./img/close.svg');
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        border: none;
        background-color: inherit;
    }

    .btn-done {
        top: 10px;
        right: 10px;
        display: inline-block;
        background-image: url('./img/done-green.svg');
        max-width: 174px;
        width: 100%;
        height: 174px;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        background-color: inherit;
        margin-top: 24px;
    }
}