.breadcrumb {

    &__nav {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-left: auto;
        margin-right: auto;
         font-size: 14px;
    }
}

.header-breadcrumb {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__btn-group {
        display: flex;
        gap: 14px;
    }

    &__btn {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        gap: 8px;
    }
}

@media (max-width: 768px) {
    .breadcrumb__nav {
        align-items: start;
        flex-direction: column;
    }
}