.input-box {
    width: 100%;
    position: relative;

    &__err {
        position: absolute;
        top: calc(100% + 4px);
        display: flex;
        gap: 4px;
        align-items: flex-start;

        .btn__err {
            width: 16px;
            height: 16px;
            border: none;
            background-color: inherit;
            background-image: url('./img/close_red.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }

        .text_red {
            color: #FF4D4F;
            font-size: 12px;
            margin: 0;
        }
    }

    .tooltip-password {

        &::after {
            content: "Пароль должен содержать не менее 8 символов, цифры и заглавные буквы ";
            z-index: 1;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -18px;
            left: 8px;
            color: #89AAC7;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }

    .autorization__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 0px;
        margin-top: 20px;
        border-radius: 16px;
        border-radius: 0px;
        border: none;
        background: none;


        .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
            color: #AAAAAA;
            font-size: 14px;
            padding-left: 8px;

            &:focus {
                color: #AAAAAA;
            }
        }

        .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
            color: #AAAAAA;
        }

        div {
            border: none;
            background: none;
            border: 1px solid #F0F0F0;
            border-radius: 8px;

            &::after {
                display: none;
            }

            &::before {
                display: none;
            }
        }


        input {
            background-color: white;
            border-radius: 8px;
            font-size: 14px;

            &:focus {
                border: 1px solid rgba(24, 144, 255, 1);
                -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
                -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
                box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            }
        }

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: black;
        }

        &_err {
            border: 1px solid #FF4D4F;

        }
    }

    .input-err {

        div {
            border: 1px solid #FFD8D8;
            border-radius: 8px;

            input {
                background-color: #FFF5F5;

                &:focus {
                    border: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            }


        }



    }
}