.profile-settings {
    z-index: 10;
    display: flex;
    gap: 1px;
    flex-direction: column;
    height: 100px;
    background-color: aquamarine;
    position: absolute;
    right: 40px;
    min-width: 160px;
    height: 100px;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    background-color: #F0F0F0;

    &__btn {
        height: 33.33%;
        background-color: white;
        border: none;
        cursor: pointer;
        text-align: left;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 14px;

        &_exit {
            background-image: url('./img/exit.svg');
            background-repeat: no-repeat;
            background-position: center right 12px;
            background-size: 16px;
        }

        &:hover {
            background-color: #E6F7FF;
            transition: background-color 0.3s;
            color: #1890FF;
        }

        &:active {
            background-color: #E6F7FF;
            transition: background-color 0.3s;
            color: #1890FF;
        }

        &:nth-child(1) {
            border-radius: 16px 16px 0 0;
        }

        &:last-child {
            border-radius: 0 0 16px 16px;
        }
    }
}