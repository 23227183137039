.btn__green,
.btn__blue,
.btn__grey,
.btn__bej,
.btn__white {
    width: 100%;
    min-height: 32px;
    font-size: 14px;
    max-width: 200px;
    border-radius: 16px;
    background-color: #27BC62;
    border: none;
    color: white;
    font-family: "Inter";
    min-width: 120px;
    cursor: pointer;
    margin: 0;

    &[disabled] {
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: white;
            position: absolute;
            border-radius: 8px;
            opacity: 0.5;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:hover {
            box-shadow: none;
        }

    }

    &:hover {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }
}

.btn__bej {
    background-color: #D1A77E;
}
.btn__blue {
    background-color: #1890FF;
    margin-top: 0;

}

.btn__grey {
    background-color: #F0F0F0;
    color: black;
}

.back-button {
    border: none;
    background: none;
    cursor: pointer;
}

.btn__edit-light,
.btn__delete-light {
    width: 22px;
    height: 16px;
    cursor: pointer;
    background-image: url('../img/icon-edit.svg');
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-color: inherit;
    background-size: 15px;

    &[disabled] {
        cursor: not-allowed;
    }
}

.btn__delete-light {
    background-image: url('../img/icon-delete.svg');
    background-size: 14px;
}

.btn__white {
    background-color: white;
    color: #27BC62;
    border: 1px solid #27BC62;
}
