.modal-box.modal-error {
    z-index: 1000;
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .modal-body {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        justify-content: space-between;
        background-color: #F5F5F5;
        width: 100%;
        min-height: 240px;
        border-radius: 16px;
        position: relative;
        max-width: 640px;
        margin-top: 0px;
        margin-top: 200px;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
    
        .modal__group-btn {
            right: 24px;
            top: 24px;
        }
    
        .title {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
        }
    
        .text {
            text-align: center;
            margin-top: 32px;
            font-size: 20px;
        }
    
        .btn__blue-modal {
            cursor: pointer;
            width: 100%;
            height: 56px;
            background-color: #E6F7FF;
            border-radius: 0 0 16px 16px;
            border: none;
            height: 56px;
            color: #1890FF;
            font-size: 16px;
        }
    }
}