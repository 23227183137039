@import '../../common/styles/mixins.scss';
@import '../../common/styles/svg_img.scss';

.autorization {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(560px + 96px);
    width: 100%;
    height: auto;
    background-color: #F5F5F5;
    padding-left: 48px;
    padding-right: 48px;
    justify-content: space-between;
    margin-top: 150px;

  &__content {
    background-color: #F5F5F5;
  }
    
    .back-block {
        width: 100%;

        .text_back {
            display: flex;
            color: #1890FF;
            font-size: 14px;
            align-items: center;
            gap: 12px;

            &::before {
                content: '';
                background-image: url('./img/arrow-back.svg');
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 24px;
            }
        }

        .btn__blue {
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            min-height: 40px;
            font-size: 14px;
        }
    }

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 20px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &:focus {
            outline: none;
            border: 1px solid #1890FF;
            -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
        }

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: rgb(179, 179, 179);
        }

        &_err {
            display: block;
            width: 100%;
            max-height: 56px;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 16px 20px;
            margin-top: 32px;
            border: 1px solid #F0F0F0;
            border-radius: 16px;
            border: 1px solid #FF4D4F;
            position: relative;
        }
    }


    .btn__blue {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        min-height: 40px;
        font-size: 14px;
        border-radius: 8px;
        margin-top: 32px;
    }

    &__footer {
        width: 100%;
        max-width: 1440px;
        padding: 40px 48px;
        background-color: inherit;
        height: auto;
        margin-top: auto;

    }

  

    .bottom-box {
        display: flex;
        align-items: center;
        margin-top: 12px;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;

        .text_blue {
            font-size: 14px;
        }
    }
}

.input-with-btn {
    width: 100%;
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;

    .input-box {
        width: 100%;
    
        .autorization__input-field {
            width: 100%;
            max-height: 56px;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 0px;
            margin-top: 20px;
            border-radius: 16px;
            border-radius: 0px;
            border: none;
            background: none; 
            
            
            .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
            color: #AAAAAA;
            font-size: 14px;
            padding-left: 8px;
    
            &:focus {
                color: #AAAAAA;
            }
        }
    
        .css-o943dk-MuiFormLabel-root-MuiInputLabel-root   {
            color: #AAAAAA;
        }
    
            div {
                border: none;
                background: none;
                border: 1px solid #F0F0F0;
                border-radius: 8px;
    
                &::after {
                    display: none;
                }
    
                &::before {
                    display: none;
                }
            }
    
    
            input {
                background-color: white;
                border-radius: 8px;
                font-size: 14px;
    
                &:focus {
                    border: 1px solid rgba(24, 144, 255, 1);
                    -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
                    -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
                    box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
                }
            }
    
            &::placeholder {
                font-size: 14px;
                font-family: "Inter";
                color: black;
            }
    
            &_err {
                border: 1px solid #FF4D4F;
    
            }
        }
    }

    &_pas {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Введеные пароли не совпадают";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -26px;
            color: #848484;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }

    &_password {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        // &::after {
        //     content: "Пароль должен содержать не менее 8 символов и включать в себя цифры и латинские буквы нижнего (a...z) и верхнего (A...Z) регистра";
        //     z-index: 100;
        //     display: inline-block;
        //     position: absolute;
        //     width: 100%;
        //     bottom: -40px;
        //     color: rgb(179, 179, 179);
        //     height: 16px;
        //     font-family: "Inter";
        //     font-size: 12px;
        // }
    }

    &_active {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Данный логин уже зарегистрирован в системе";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -26px;
            color: #FF4D4F;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }


}


.checkbox {

    &__box {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        width: 100%;
        justify-content: space-between;

        label {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        & .text_red {
            margin-top: 0;
            color: #FF4D4F;
        }

    }


}

.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        text-decoration: underline;
        color: #1890FF;
        
    }

    &_red {
        color: #FF4D4F;
        margin-top: 24px;
    }
}

.title {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
}

.input-with-btn {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
}

.hide,
.show {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 14px;
    @include optimizeSafariSvg(url($hide));
    background-color: unset;
    border: none;

    &::before {
        top: 2px;
    }
}

.show {
    @include optimizeSafariSvg(url($show));

    &::before {
        top: 2px;
    }
}

.check-your-mail__box {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.checkbox__box_registration {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    label {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.bottom-box-registration {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 32px;

    .btn__blue {
        margin: 0;
        width: 100%;
        max-width: 100%;
        min-height: 40px;
        min-width: 100%;
        border-radius: 8px;
        font-size: 14px;
    }
}