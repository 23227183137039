@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Medium.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Circe Rounded;
    src: url("../public/font/CirceRounded-Regular.woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Montserrat;
    src: url("../public/font/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter-Light.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: url("../public/font/IBMPlexSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans' !important;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

body {
  
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
}

.main {
    display: flex;
    width: 100%;
    height: auto;
    background-color: #F6F6F7;
}

#root {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.content {
    display: flex;
    flex-shrink: 1;
    width: 100%;
    height: auto;
    min-height: 764px;
    flex-direction: column;
}

.b24-form{
    position: absolute;
    right: 2em;
    bottom: 1em;
}