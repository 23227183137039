.notification-list {
    z-index: 10;
    width: 320px;
    max-height: 400px;
    height: 400px;
    background-color: #F5F5F5;
    border-radius: 16px;
    position: absolute;
    left: calc(-160px + 16px);
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);

    &__title-and-btn-box {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 48px;

        .title {
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__list-box {
        display: flex;
        flex-direction: column;
        gap: 1px;
        width: 100%;
        height:calc(100% - 48px);
        border-radius: 0 0 16px 16px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track-piece {
           
            background-color: #F6F1F1;
        }

        &::-webkit-scrollbar-thumb:vertical {
            width: 5px;
            height: 5px;
            background-color: #C6C6C6;
            scrollbar-width: none;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            height: 5px;
            background-color: #D9D9D9;
            border-radius: 10px;
        }
    }

    &__item {
        width: 100%;
        padding: 8px 16px;
        background-color: white;
        position: relative;

        .text {
            font-size: 12px;
            margin-top: 8px;
            display: inline-block;
        }

        .text-date {
            font-size: 12px;
            color: #888888;
        }
    }
}

.btn__blue-text {
    color: #1890FF;
    font-size: 14px;
    font-weight: 400;
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.btn__close-thin {
    width: 8px;
    height: 8px;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    border: none;
    background-color: inherit;
    background-image: url('./img/close.svg');
}

.btn__read-more {
   display: inline;
    white-space: nowrap;
    color: #1890FF;
    font-size: 12px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
}