// Основной блок

.main-sidebar {
    display: block;
    width: 100%;
    padding: 0.5em 50px;
    background-color: white;
    height: 60px;
    border-bottom: 1px solid #D9D9D9;
    position: sticky;
    top: 0;
    z-index: 10;

    &__nav {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 1.25em;
        margin-left: auto;
        margin-right: auto;
    }

    &__group-btn {
        display: flex;
        gap: 0.63em;
    }

    &__left-box {
        display: flex;
        gap: 40px;
         width: 100%;
        align-items: center;
    }

    &__right-box {
        display: flex;
        gap: 1em;
        align-items: center;
    }
}

// Кнопки

.notifications__btn,
.message__btn,
.upload__btn,
.task__btn,
.setting__btn {
    display: inline-block;
    position: relative;
    background-color: #F5F5F5;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: none;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
        transition: background-color 0.3s;
    }

}

.notifications {
    &::before {
        content: "9";
        color: white;
        position: absolute;
        top: -2px;
        right: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-color: #48A8FE;
        border-radius: 50%;
    }
}

.notifications__btn {
    background-image: url('./img/bell.svg');
}

.task__btn {
    background-image: url('./img/task.svg');
}

.upload__btn {
    background-image: url('./img/upload.svg');
}

.message__btn {
    background-image: url('./img/message.svg');
}

.setting__btn {
    background-image: url('./img/setting.svg');
}

// Поле ввода
.input-field {
    border: 1px solid #F0F0F0;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    min-width: 100px;
    max-width: 446px;
    padding: 0.63em 0.63em 0.63em 2.3em;
    background-image: url("./img/search.svg");
    background-repeat: no-repeat;
    background-position: 15px center;
    font-family: 'Inter';
    font-size: 0.9em;
    color: black;
    cursor: pointer;

    &::placeholder {
        font-family: 'Inter';
        font-size: 0.9em;
        color: #D9D9D9;
    }

    &:focus {
        outline: 1px solid grey;
    }
}

// Блок профиля

.profile-box {
    display: flex;
    align-items: center;
    gap: 0.5em;

    &__btn {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        background-image: url('./img/profile.svg');
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }

    &__user-name {
        font-family: 'Inter';
        font-size: 0.9em;
    }
}

.training-nav {
    display: flex;
    gap: 40px;
    align-items: center;

    &__item {
        font-size: 14px;
    }

    &__item_active {
        font-size: 20px;
    }
}

.language-selection {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    padding: 10px 0px;
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    order: 2;
    margin-left: auto;
    padding-left: 8px;
    padding-right: 8px;

    .text {
        font-size: 14px;

        &::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('https://dl.ecomru.ru/svg-storage/lang.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            margin-right: 4px;
            vertical-align: text-bottom;
        }

        &::after {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('https://dl.ecomru.ru/svg-storage/drop-grey-bottom.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            margin-left: 4px;
            vertical-align: middle;
        }
    }

    .text.opened {
        &::after {
            transform: rotate(180deg);
        }
    }
}

.select-language {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    width: 80px;
    height: auto;
    z-index: 1;
    border-radius: 16px;
    top: calc(100% + 8px) ;
    border: 1px solid #F0F0F0;
    background-color: white;
    padding-bottom: 16px;
    padding-top: 16px;
    -webkit-box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);
}

.btn__language {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 16px;
    border: none;
    background-color: inherit;
    padding-left: 20px;
    text-align: left;


    &__active {
        &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 5px;
            display: inline-block;
            width: 10px;
            height: 10px;
            background-image: url('https://dl.ecomru.ru/svg-storage/done-active.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            margin-left: auto;
            vertical-align: middle;
        }
    }


    &_tur {
        border-radius: 16px 16px 0 0;

        &::after {
            content: "";
            position: absolute;
            right: 8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('https://dl.ecomru.ru/svg-storage/tur.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            margin-left: auto;
            vertical-align: middle;
        }
    }

    &_en {
        border-radius: 0 0 16px 16px;

        &::after {
            content: "";
            position: absolute;
            right: 8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('https://dl.ecomru.ru/svg-storage/en.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            margin-left: auto;
            vertical-align: middle;
        }
    }

    &_ru {
        border-radius: 0 0 16px 16px;

        &::after {
            content: "";
            position: absolute;
            right: 8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('https://dl.ecomru.ru/svg-storage/ru.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            margin-left: auto;
            vertical-align: middle;
        }
    }
}